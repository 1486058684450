'use client';

import { redirect } from 'next/navigation';

import { useLocalisation } from '@lib/machine-parts/storefront/utils';

export default function NotFound() {
    const { language, locale } = useLocalisation();
    redirect(`/${language}/${locale}/not-found`);
}
